import MatrixGallery_MatrixGalleryTextOnCenterSkinComponent from '@wix/thunderbolt-elements/src/components/NativeGalleries/MatrixGallery/viewer/skinComps/MatrixGalleryTextOnCenterSkin/MatrixGalleryTextOnCenterSkin.skin';
import MatrixGallery_MatrixGalleryTextOnCenterSkinController from '@wix/thunderbolt-elements/src/components/NativeGalleries/MatrixGallery/viewer/MatrixGallery.controller';


const MatrixGallery_MatrixGalleryTextOnCenterSkin = {
  component: MatrixGallery_MatrixGalleryTextOnCenterSkinComponent,
  controller: MatrixGallery_MatrixGalleryTextOnCenterSkinController
};


export const components = {
  ['MatrixGallery_MatrixGalleryTextOnCenterSkin']: MatrixGallery_MatrixGalleryTextOnCenterSkin
};


// temporary export
export const version = "1.0.0"
